//palette for Casino Fiz
$bg-color: #090A4E;
$text-color: #fdfcfb;
$primary: #fcc000;
$success: #1ce066;
$info: #006cff;
$danger: #C61E18;


$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;

//menu in primary color
.sticky-sidebar {
  .nav-link:hover, .active-link {
    color: $primary !important;
    .variable-svg-icon {
      background-color: $primary !important;
    }
  }
  //TODO language dropdown stay hover color = primary when opened area-expanded true
  .dropdown-item:active {
    background-color: $primary !important;
  }
}



#category-nav li button {
  &:hover, &.active {
    --bs-nav-pills-link-active-color: #{$bg-color} !important;
  }
}

